import utils from '../utils/utils'
import anime from '../libs/anime'
import globalVar from '../utils/globalVar'

class Nav {
  constructor() {
    let scrollPos;
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
      cache.nav = document.querySelector('header');
      cache.navBtn = cache.nav.querySelectorAll('.right .item.parent');
      cache.navmobile = cache.nav.querySelector('.nav-mobile');
      cache.navmobileBtn = cache.nav.querySelector('.burger');
      if(cache.navmobile.querySelector('.item.parent')) {
        cache.navMobileSubBtn = cache.navmobile.querySelectorAll('.item.parent');
      }
      if(cache.nav.querySelector('.sub .back')) {
        cache.navMobileCloseSubBtn = cache.nav.querySelectorAll('.sub .back');
      }
    };

    let registerEvents = () => {
      window.addEventListener('scroll', scrollDetect);
      cache.navmobileBtn.addEventListener("click", openNav);
      for (let i = 0; i < cache.navBtn.length; i++) {
        cache.navBtn[i].addEventListener("click", toggleSubMenu);
      }
      if(cache.navmobile.querySelector('.item.parent')) {
        for (let i = 0; i < cache.navMobileSubBtn.length; i++) {
          cache.navMobileSubBtn[i].addEventListener("click", openMobileSubMenu);
        }
      }
      if(cache.nav.querySelector('.sub .back')) {
        for (let i = 0; i <  cache.navMobileCloseSubBtn.length; i++) {
          cache.navMobileCloseSubBtn[i].addEventListener("click", closeMobileSubMenu );
        }
      }
    };


    let toggleSubMenu = function() {
      if(!cache.nav.classList.contains('open')) {
        cache.nav.classList.add('open');
        cache.nav.addEventListener("mouseleave", closeSubMenu);
        window.addEventListener('scroll', closeSubMenu);
      }
      if(this.classList.contains('nav_active')) {
        closeSubMenu();
      } else {
        if(cache.nav.querySelector('.sub.nav_active')) {
          const subnav_active = cache.nav.querySelector('.sub.nav_active');
          subnav_active.classList.remove('nav_active');
          cache.nav.querySelector('.right .item.parent.nav_active').classList.remove('nav_active');
          anime({
            targets: subnav_active,
            opacity: [1,0],
            translateY: [0,-100],
            duration: 400,
            easing: 'easeOutQuad',
            complete: function() {
              subnav_active.style.display = "none";
            }
          });
        }
  
        const targetBtn = this;
        const sub = cache.nav.querySelector('.'+targetBtn.dataset.sub);
        targetBtn.classList.add('nav_active');
        sub.style.display = "flex";
        sub.classList.add('nav_active');
        anime({
          targets: sub,
          opacity: [0,1],
          translateY: [-100,0],
          duration: 400,
          easing: 'easeOutQuad',
        });
  
      }
      
    };

    let closeSubMenu = function() {
        const subnav_active = cache.nav.querySelector('.sub.nav_active');
        subnav_active.classList.remove('nav_active');
        cache.nav.querySelector('.right .item.parent.nav_active').classList.remove('nav_active');
        cache.nav.classList.remove('open');
        cache.nav.removeEventListener("mouseleave", closeSubMenu);
        window.removeEventListener('scroll', closeSubMenu);
        anime({
          targets: subnav_active,
          opacity: [1,0],
          translateY: [0,-100],
          duration: 400,
          easing: 'easeOutQuad',
          complete: function() {
            subnav_active.style.display = "none";
          }
        });
    }


    // MOBILE

    let openNav = () => {
      cache.navmobileBtn.removeEventListener("click", openNav);
      cache.navmobileBtn.classList.add('open');
      cache.navmobile.style.display="flex";
      document.querySelector('body').classList.add('overflow');
      document.querySelector('html').classList.add('overflow');

      anime({
        targets: cache.navmobile,
        opacity: [0,1],
        translateX: [250,0],
        duration: 400,
        easing: 'easeOutQuad',
        complete: function() {
          cache.navmobileBtn.addEventListener("click", closeNav);
        }
      });
    }

    let closeNav = () => {
      cache.navmobileBtn.removeEventListener("click", closeNav);
      document.querySelector('body').classList.remove('overflow');
      document.querySelector('html').classList.remove('overflow');
      cache.navmobileBtn.classList.remove('open');
      if(cache.nav.querySelector('.sub.nav_active')) {
        const sub = cache.nav.querySelector('.sub.nav_active');
        anime({
          targets: sub,
          opacity: [1,0],
          translateX: [0,250],
          duration: 400,
          easing: 'easeOutQuad',
          complete: function() {
            sub.classList.remove('nav_active');
            sub.style.display = "none";
          }
        });
      }
      anime({
        targets: cache.navmobile,
        opacity: [1,0],
        translateX: [0,250],
        duration: 400,
        easing: 'easeOutQuad',
        complete: function() {
          cache.navmobileBtn.addEventListener("click", openNav);
          cache.navmobile.style.display="none";
        }
      });
    }

    let openMobileSubMenu = function() {
      const sub = cache.nav.querySelector('.'+this.dataset.sub);
        sub.style.display = "flex";
        sub.classList.add('nav_active');
        anime({
          targets: sub,
          opacity: [0,1],
          translateX: [250,0],
          duration: 400,
          easing: 'easeOutQuad',
        });
    }

    let closeMobileSubMenu = function() {
        const sub = cache.nav.querySelector('.sub.nav_active');
        anime({
          targets: sub,
          opacity: [1,0],
          translateX: [0,250],
          duration: 400,
          easing: 'easeOutQuad',
          complete: function() {
            sub.classList.remove('nav_active');
            sub.style.display = "none";
          }
        });
    }

    let scrollDetect = function() {
      let up = false;
      if(scrollPos > window.pageYOffset) {
        up = true;
      }
      scrollPos = window.pageYOffset;

      if(!up && (scrollPos > window.innerHeight) && !cache.nav.classList.contains('up')) {
        document.querySelector('body').classList.add('up');
        cache.nav.classList.add('up');
        anime({
          targets: cache.nav,
          translateY: ['0%', '-100%'],
          duration: 500,
          easing: 'easeOutQuad'
        });
      } else if(up && (scrollPos > window.innerHeight) && cache.nav.classList.contains('up')) {
        document.querySelector('body').classList.remove('up');
        cache.nav.classList.remove('up');
        anime({
          targets: cache.nav,
          translateY: ['-100%', '0%'],
          duration: 500,
          easing: 'easeOutQuad',
        });
      }
    }

    initFunc();
  };
}
export { Nav }
