import anime from '../libs/anime'
import globalVar from '../utils/globalVar'

class Blog {
  constructor() {
    let cache = {};
    const url = window.location.href;
    const total_posts = parseInt(document.querySelector('.template-archive-blogue .load-more_container .total_posts').innerHTML);
    const ppp = 6;
    let offset = 0;
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
      cache.page = document.querySelector('.template-archive-blogue');
      cache.loadMore = cache.page.querySelector('.load_more');
      if (url.match(/\?./)) {
        anime({
              targets: globalVar.scrollElement,
              scrollTop: document.querySelector('#archive').getBoundingClientRect().top,
              duration: 100,
              easing: 'easeOutQuad'
          });
      }
    };

    let registerEvents = () => {
      if(cache.page.querySelector('.load_more')) {
        cache.loadMore.addEventListener('click', loadMore);
      }
    };

    let loadMore = () => {
      offset += ppp;
      let str = '&offset=' + offset + '&ppp=' + ppp;
      if(url.match(/\?./)) {
          str += '&cat='+url.split('cat=')[1];
          str += '&s='+url.split('s=')[1];
      }
      str += '&action=filter_posts_ajax';
      
      jQuery.ajax({
        type: "GET",
        dataType: "html",
        url: ajaxurl,
        data: str,
        success: function(data){
          data = jQuery(data);
          if(data.length){
            const archive = document.querySelector('.template-archive-blogue .articles_container');

            for (let i = 0; i < data.length; i++) {
              if(data[i]) {
                archive.append(data[i]);
              }
            }
            const nb_past_post = parseInt(document.querySelector('.template-archive-blogue .load-more_container .nb_post').innerHTML);
            const nb_post = data.length + nb_past_post;
            document.querySelector('.template-archive-blogue .load-more_container .nb_post').innerHTML = nb_post;
            if(nb_post == total_posts) {
              cache.loadMore.style.display = "none";
            }
          } 
        }
      })

    }

    initFunc();
  };
}

export { Blog }