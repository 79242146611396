import anime from '../libs/anime'
import Hammer from '../libs/hammer'

class Module_26_Promo {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
        cache.slider = document.querySelectorAll('.module-26-promo.layout-slider, .module-26-promo.layout-disney');
    };

    let registerEvents = () => {  
      for (let i = 0; i < cache.slider.length; ++i) {
          if(cache.slider[i].querySelector('.desktop .arrow_left')) {
            cache.slider[i].querySelector('.desktop .arrow_left').addEventListener("click", slideLeft);
            cache.slider[i].querySelector('.desktop .arrow_right').addEventListener("click", slideRight);
          } if(cache.slider[i].querySelector('.mobile .arrow_left')) {
            cache.slider[i].querySelector('.mobile .arrow_left').addEventListener("click", slideLeft);
            cache.slider[i].querySelector('.mobile .arrow_right').addEventListener("click", slideRight);
          }
          if(cache.slider[i].scrollWidth < cache.slider[i].offsetWidth) {
            cache.slider[i].querySelector('.desktop .arrow_left').style.display = 'none';
            cache.slider[i].querySelector('.desktop .arrow_right').style.display = 'none';
            cache.slider[i].querySelector('.mobile .arrow_left').style.display = 'none';
            cache.slider[i].querySelector('.mobile .arrow_right').style.display = 'none';
          }
        }
      
    };

    let slideLeft = function() {
        const parent = this.closest('.module-26-promo');
        const slider = parent.querySelector('.promo_slider, .promo_disney');
        const dist = slider.scrollLeft - (slider.offsetWidth/1.5);
        if(dist < 0){
          anime({
            targets: slider,
            scrollLeft: slider.scrollWidth,
            duration: 200,
            easing: 'easeOutCubic',
        });
        } else {
          anime({
            targets: slider,
            scrollLeft: dist,
            duration: 100,
            easing: 'easeOutCubic',
          });
        }
    }
    let slideRight = function() {
        const parent = this.closest('.module-26-promo');
        const slider = parent.querySelector('.promo_slider, .promo_disney');
        const dist = slider.scrollLeft + (slider.offsetWidth/1.5);
        if(dist > (slider.scrollWidth - (slider.offsetWidth/1.5))){
            anime({
              targets: slider,
              scrollLeft: 0,
              duration: 200,
              easing: 'easeOutCubic',
          });
        } else {
          anime({
            targets: slider,
            scrollLeft: dist,
            duration: 100,
            easing: 'easeOutCubic',
        });
        }
    }

    initFunc();
  };
}
export { Module_26_Promo }