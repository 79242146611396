import utils from '../utils/utils'
import anime from '../libs/anime'
import globalVar from '../utils/globalVar'

class BlogNav {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
      cache.navParent = document.querySelector('.blog_nav');
      cache.navBtn = cache.navParent.querySelector('.nav-mobile');
      cache.nav = cache.navParent.querySelector('.content_nav');
      cache.navSub = cache.navParent.querySelectorAll('.cat .arrow_btn');
    };

    let registerEvents = () => {
      cache.navBtn.addEventListener('click', toggleNav);
      if(cache.navSub) {
        for (let i = 0; i < cache.navSub.length; i++) {
          cache.navSub[i].addEventListener('click', toggleSubNav);
        }
      }
    };

    let toggleNav = () => {
      if(!cache.navParent.classList.contains('open')) {
        cache.navParent.classList.add('open');
        cache.nav.style.display = 'block';
        anime({
          targets: cache.nav,
          opacity: [0,1],
          translateY: [-20,0],
          duration: 400,
          easing: 'easeOutQuad',
        });
      } else {
        cache.navParent.classList.remove('open');
        anime({
          targets: cache.nav,
          opacity: [1,0],
          translateY: [0,-20],
          duration: 400,
          easing: 'easeOutQuad',
          complete: function() {
            cache.nav.style.display = 'none';
          }
        });
      }
    }

    let toggleSubNav = function() {
      if(globalVar.tablet.matches) {
        console.log(this);
        if(!this.classList.contains('open')) {
          this.classList.add('open');
          const sub = this.closest('.cat').querySelector('.sub-menu');
          sub.style.display = 'block';
          sub.style.height = 'auto';
          const height = sub.offsetHeight;
          sub.style.height = 0;
          anime({
            targets: sub,
            height: [0,height],
            opacity: [0,1],
            translateY: [-20,0],
            duration: 400,
            easing: 'linear',
          });
        } else {
          this.classList.remove('open');
          const sub = this.closest('.cat').querySelector('.sub-menu');
          anime({
            targets: sub,
            height: 0,
            opacity: [1,0],
            translateY: [0,-20],
            duration: 400,
            easing: 'linear',
            complete: function() {
              sub.style.display = 'none';
            }
          });
        }
      }
    }

    initFunc();
  };
}

export { BlogNav }